import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import "react-responsive-carousel/lib/styles/carousel.min.css";
import HomeLogos from "../components/home_logos";
import Carousel from "../components/home_carousel";
import Section1Col from "../components/section_1col";
import Section2Col from "../components/section_2col";
import Section4Col from "../components/section_4col";

const IndexPage = () => (
    <Layout>
        <SEO title="Home" bodyClasses="home blog"/>

        <HomeLogos/>
        <Carousel/>
        <Section1Col/>
        <Section2Col/>
        <Section4Col/>
        <hr/>
    </Layout>
);


export default IndexPage
